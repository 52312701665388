body {
  margin: 0;
  font-family: 'Montserrat', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: 'Montserrat', sans-serif;
}

html, body {
	height: 100%;
}
body {
	margin: 0;
	padding: 0;
	font-family: 'Montserrat', sans-serif;
	font-size: 15px;
	font-weight: 400;
	line-height: 1.75;
	letter-spacing: 1px;
	background-color: #fff;
	color: #484848;
}
h1,
h2,
h3,
h4,
h5,
h6 {
	font-weight: 700;
	line-height: 1.2;
	color: #333;
	font-family: 'Montserrat', sans-serif;
}
p {
	font-size: 16px;
	line-height: 26px;
}
b, strong {
	font-weight: 700;
}
dfn, cite, em, i {
  	font-style: italic;
}
h1 {
	font-size: 36px;
}
h2 {
	font-size: 26px;
}
h3 {
	font-size: 24px;
}
h4 {
	font-size: 22px;
}
h5 {
	font-size: 18px;
}
h6 {
	font-size: 16px;
}
a, a:focus, a:hover, a:active {
	text-decoration: none;
	color: inherit;
	outline: none;
}
ul, ol {
	margin: 0;
	padding: 0;
}
ul {
  list-style: none;
}
img {
	display: block;
	max-width: 100%;
	height: auto;
}

.effect {
	-webkit-transition: all .4s ease-in-out;
	-moz-transition: all .4s ease-in-out;
	-o-transition: all .4s ease-in-out;
	transition: all .4s ease-in-out;
}
.hor-center {
	position: absolute;
	left: 50%;
	-webkit-transform: translateX(-50%);
	-moz-transform: translateX(-50%);
	-ms-transform: translateX(-50%);
	transform: translateX(-50%);
}
.ver-center {
	position: absolute;
	top: 50%;
	-webkit-transform: translateY(-50%);
	-moz-transform: translateY(-50%);
	-ms-transform: translateY(-50%);
	transform: translateY(-50%);
}
.center {
	position: absolute;
	left: 50%;
	top: 50%;
	-webkit-transform: translate(-50%, -50%);
	-moz-transform: translate(-50%, -50%);
	-ms-transform: translate(-50%, -50%);
	transform: translate(-50%, -50%);
}

.mb-30 {
  	margin-bottom: 30px;
}

.page-table {
	display: table;
	width: 100%;
	height: 100%;
}
.table-cell {
	position: relative;
	display: table-cell;
	vertical-align: middle;
	padding-top: 20px;
	z-index: 5;
}

.section {
	background-color: #fff;
  	padding: 100px 0;
}
.second-bg {
	background-color: #fafafa;
}
.section-title {
	display: block;
	width: 100%;
	margin-bottom: 60px;
	text-align: center;
}
.section-title h2 {
	position: relative;
	display: inline-block;
	margin: 0;
	padding-bottom: 15px;
	font-size: 36px;
	font-weight: 700;
	text-transform: capitalize;
}
.section-title h2:after {
	content: '';
	position: absolute;
	bottom: 0;
	left: 4px;
	width: 50px;
	height: 2px;
	background-color: #444444;
}


body > #root > div {
	height: 100vh;
  }