/*----------------------------------------
Hot Dice
----------------------------------------*/
.hotdice {
  position: relative;
  min-height: 550px;
  background-color: rgb(248, 248, 249);
  padding-bottom: 50px;
}

#hotdice h1 {
  text-align: center;
  font-size: 2.6em;
  margin: 50px 20px 0 20px;
  padding: 50px 0 0 0;
  color: red;
  margin-bottom: 20px;
}

#hotdice p {
  text-align: center;
  margin: 50px 50px 50px 50px;
  font-family: 'Montserrat', sans-serif;
  font-size: 1.2em;
}

.dice-row {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.dice-column {
  display: flex;
  flex-direction: column;
}

.img-hotdice {
  height: 600px;
  margin-left: 50px;
}

@media (max-width: 900px) {
  #hotdice .image-icon {
    height: 70px;
    margin-bottom: 20px;
  }
}

@media (max-width: 650px) {
  .img-hotdice {
    margin-left: 0;
  }
  .dice-row {
    flex-direction: column;
  }
}
