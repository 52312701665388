/*----------------------------------------
Hot Dice
----------------------------------------*/
.footer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 300px;
  background-color: #090f15;
}

.footer-title {
  color: white;
  margin-bottom: 40px;
}

.icons-container {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  min-width: 280px;
}

.footer-icon {
  width: 50px;
}
