/*----------------------------------------
Hot Ways
----------------------------------------*/
.hotways {
    display: flex;
    flex-flow: column wrap;
	min-height: 550px;
    background-color: white;
}

.image__container {
    display: flex;
    justify-content: center;
    align-items: center;
}

#hotways h1 {
    text-align: center;
	font-size: 2.6em;
    margin: 50px 20px 0 20px;
    color: red;
    margin-bottom: 20px;
}

#hotways p {
    text-align: center;
    margin: 50px 50px 0 50px;
    font-family: 'Montserrat', sans-serif;
    font-size: 1.2em;
}

@media (max-width:1660px) {
    .image {
        max-width: 80%;
    }
}

@media (max-width:525px) {
    #hotways .img-ways {
        margin: 20px 0 20px 0;
    }

    #hotways .image-icon {
        height: 70px;
        margin-bottom: 20px;
    }
}
