.storebuttons-container {
    margin-bottom: 50px;
}

h2 {
    text-align: center;
    margin-top: 0;
}

.image-container {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-evenly;
}

.image-icon {
    height: 80px;
    cursor: pointer;
}
