/*----------------------------------------
Home
----------------------------------------*/
.home-1 {
	position: relative;
	height: 100%;
	min-height: 550px;
	background-image: url(../../public/images/bkg.jpg);
	background-repeat: no-repeat;
	background-position: center;
	-webkit-background-size: cover;
	-moz-background-size: cover;
	-ms-background-size: cover;
	background-size: cover;
}
.home-1:after {
	content: "";
	position: absolute;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	z-index: 0;
}

.home-s-btn {
	display: block;
	bottom: 50px;
	height: 40px;
	width: 26px;
	border: 2px solid #efefef;
	-webkit-border-radius: 14px;
	-moz-border-radius: 14px;
	border-radius: 14px;
}
.home-s-btn .dot {
	display: block;
	height: 6px;
	width: 6px;
	background-color: #efefef;
	-webkit-border-radius: 50%;
	-moz-border-radius: 50%;
	border-radius: 50%;
	-webkit-animation: dot-animation 1.8s infinite ease-in-out;
	-moz-animation: dot-animation 1.8s infinite ease-in-out;
	animation: dot-animation 1.8s infinite ease-in-out;
}

.table-cell {
    vertical-align: bottom;
}

.Typist {
    font-size: 54px;
    margin: 50px;
    text-align: center;
    color: white;
    line-height: normal;
}


.x-sign {
    color: #fffcfb;
    --color1: #f9bbf2;
    --color2: #f976eb;
    --color3: #fb28e5;

    --interval: 2s;
    display: block;
    text-shadow: 
      0 0 3px var(--color1),
      0 0 5px var(--color2),
      0 0 10px var(--color3);
    will-change: filter, color;
    filter: saturate(60%);
    animation: flicker steps(100) var(--interval) 2s infinite;
  }
    
  @keyframes flicker {
    50% {
    
      color: white;
      filter: saturate(200%) hue-rotate(20deg);
    }
}

@media (max-width: 525px) {
    .Typist {
        font-size: 40px;
    }
}

/*Smooth Scroll Button animation*/
@-webkit-keyframes dot-animation {
    0% {
        top: -webkit-calc(50% - 6px);
        top: calc(50% - 6px);
        opacity: 1;
    }
    40% {
        top: 50%;
        opacity: 0;
    }
    100% {
        top: -webkit-calc(50% - 6px);
        top: calc(50% - 6px);
        opacity: 0;
    }
}
@-moz-keyframes dot-animation {
    0% {
        top: -moz-calc(50% - 6px);
        top: calc(50% - 6px);
        opacity: 1;
    }
    40% {
        top: 50%;
        opacity: 0;
    }
    100% {
        top: -moz-calc(50% - 6px);
        top: calc(50% - 6px);
        opacity: 0;
    }
}
@keyframes dot-animation {
    0% {
        top: -webkit-calc(50% - 6px);
        top: -moz-calc(50% - 6px);
        top: calc(50% - 6px);
        opacity: 1;
    }
    40% {
        top: 50%;
        opacity: 0;
    }
    100% {
        top: -webkit-calc(50% - 6px);
        top: -moz-calc(50% - 6px);
        top: calc(50% - 6px);
        opacity: 0;
    }
}